import React from "react"
import SEO from "../components/seo"

import { TextLeftImageRightBlock } from "../components/blocks/TextLeftImageRightBlock"
import { CenteredTextBlock } from "../components/blocks/CenteredTextBlock"
import BasicTemplate from "../components/BasicTemplate"
import { SERVICE_IDS, PAGE_URLS } from "../enum/PageUrls"
import { Link } from "gatsby"

const ServicesPage = () => {
  return (
    <BasicTemplate title="Our Services">
      <span>
        <SEO
          title="Services"
          description="We are here to provide for any and all of your needs. We offer Wedding Receptions, Corporate Events, Funeral Services and Catering for Sensitive Occassions, and Holiday Parties."
        />
        <CenteredTextBlock
          title="What Can We Do For You?"
          style={{ paddingBottom: 20 }}
        >
          Use the KC Hall of O'Fallon for your next event! Whether it's a
          fundraiser, wedding reception, trivia night, or any otheer event, we
          have whatever you need for your next event!
        </CenteredTextBlock>

        <TextLeftImageRightBlock
          imageUrl="https://i.imgur.com/O9LCFrd.jpg"
          title="Wedding Receptions"
          altText="Wedding Receptions"
          id={SERVICE_IDS.WEDDING_RECEPTIONS}
        >
          Whether you’re celebrating with just family, the entire neighborhood
          or a large group up to 350 people, we can find a place for you.
          <br /><br />
          See the <Link className="red-link" to={PAGE_URLS.GALLERY}>gallery</Link> to see what we've done in the past!
        </TextLeftImageRightBlock>

        <TextLeftImageRightBlock
          imageUrl="https://i.imgur.com/f9azIpp.jpg"
          title="Corporate Functions"
          altText="Corporate Functions"
          id={SERVICE_IDS.CORPORATE_FUNCTIONS}
        >
          Retirement parties, Christmas gatherings, seminars, and business
          meetings, depending on the size of the event, can be hosted in the Main
          or Lower Hall. Bring in the heavy hitters for your next company
          meeting.
          <br />
          <br />
          We can provide buffet-style meals or plated dinners for
          your board meeting, annual company picnic, shareholder events or
          conventions.
        </TextLeftImageRightBlock>

        <TextLeftImageRightBlock
          imageUrl="https://i.imgur.com/8hsZERo.jpg"
          title="Happier Holidays"
          altText="Happier Holidays"
          id={SERVICE_IDS.HAPPIER_HOLIDAYS}
        >
          Enjoy the festive holiday season instead of running yourself ragged in
          the kitchen. Spend time with your guests and enlist our help for your
          next family holiday. I’m sure we can find the meal plan and space for
          you.
        </TextLeftImageRightBlock>
 
        <TextLeftImageRightBlock
          imageUrl="https://i.imgur.com/WkY8aFx.jpg"
          title="Birthday Parties"
          altText="Birthday Parties"
          id={SERVICE_IDS.BIRTHDAY_PARTIES}
        >
          Have your birthday party at the KC Hall! We offer affordable drink packages and a cozy/fun space to celebrate that special day!
          We would love to help prepare for your special day.
        </TextLeftImageRightBlock>
        

        <TextLeftImageRightBlock
          imageUrl="https://i.imgur.com/FgzqFs6.jpg"
          title="Funeral Services and Catering for Sensitive Occasions"
          altText="Funeral Services and Catering for Sensitive Occasions"
          id={SERVICE_IDS.FUNERAL_SERVICES}
        >
          The KC Hall crew can help you in this time of need. We can assist you
          in hosting a memorial service, military event, charity function or
          fund raiser.
          <br />
          <br />
          We will work together to not only understand the meaning and impact of
          the event, but also to provide a meal that is suitable for the event.
        </TextLeftImageRightBlock>

        <TextLeftImageRightBlock
          imageUrl="https://i.imgur.com/KiprDHH.jpg"
          title="Fundraisers"
          altText="Fundraisers"
          id={SERVICE_IDS.FUNDRAISERS}
        >
          The KC of O'Fallon is the perfect venue for your next fundraiser!
          Whether your raising money for your school's sports teams, a
          charitable cause, or for any other worthy reason, the KC Hall is here
          to help at an affordable rate. Have your silent auction and trivia
          nights here!
        </TextLeftImageRightBlock>
      </span>
    </BasicTemplate>
  )
}

export default ServicesPage
